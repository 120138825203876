@import '~@angular/material/theming';

@include mat-core();

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
  --brand: 'elda';

  --ion-color-gray-light: #F5F5F5;
  --ion-color-gray-dark: #D4D4D4;
  --ion-color-gray-darkest: #1F1B1C;

  --ion-color-black:  #283F30;
  --ion-color-gold: #A38A5F;

  --quantity-selector-border-width: 2px;
  --quantity-selector-border-color: var(--ion-color-primary);
  --quantity-selector-height: 40px;
  --quantity-selector-background: white;
  --quantity-selector-text-color: var(--ion-color-primary);
  --home-preorder-type-button-background: var(--ion-color-primary);
  --preorder-type-chip-border-color: white;
  
  --payment-modal-item-background: white;
  --payment-modal-checkbox-color: black;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

